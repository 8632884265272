
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ChartOptions } from 'chart.js';
import moment from 'moment';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import FleetOccupancy from '@/modules/cars/components/price-history/fleet-occupancy.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import type Day from '@/modules/common/types/day.type';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CarsPriceHistoryService, { CarsPriceHistoryServiceS } from '@/modules/cars/cars-price-history.service';
import DayChanger from '@/modules/common/components/day-changer.vue';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import Currency from '@/modules/common/components/currency.vue';
import CarPriceTable from '@/modules/cars/components/car-price-table.vue';
import PriceHistoryGraphCars from '@/modules/cars/components/rates-graphs/price-history-graph-cars.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Percent from '@/modules/common/types/percent.type';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import PercentFilter from '@/modules/common/filters/percent.filter';
import CALENDAR_DATA_SOURCE from '@/modules/cars/constants/calendar-data-source.constant';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import { BROKER_TO_BRAND, BROKER_TO_BROKER } from '@/modules/cars/constants/car-filter-types.constant';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component({
    components: {
        DayChanger,
        CustomGraph,
        ModalWrapper,
        Currency,
        CarPriceTable,
        PriceHistoryGraphCars,
        LoaderWrapper,
        CompsetScale,
        FleetOccupancy,
    },
    filters: { MIPriceFilter, PercentFilter },
})
export default class CarsPriceHistoryPopup extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(CarsPriceHistoryServiceS) carsPriceHistoryService!: CarsPriceHistoryService;
    @Inject(CarsServiceS) carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    modes: any = { Brand: BROKER_TO_BRAND, Broker: BROKER_TO_BROKER };

    @Prop({ required: false })
    private historyDay!: number;

    currentTableDay = 0 as Day;

    get title() {
        return this.carsFiltersService.selectedLocationName;
    }

    get graphDay() {
        return this.currentTableDay || this.carsService.firstAvailableDate as Day;
    }
    set graphDay(day) {
        this.currentTableDay = day;
    }

    get fleetOccupancy() {
        return this.carsPriceHistoryService.getOccupancy(this.scanDate);
    }

    get currentDay(): Day {
        return parseInt(this.$route.params.historyDay, 10) as Day;
    }

    setDay(day: any) {
        const newDay = Number.isNaN(day) ? 0 : Math.abs(Number(day));
        this.graphDay = newDay as Day;
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 4,
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    get calendarDataSource() {
        return CALENDAR_DATA_SOURCE.HISTORY;
    }

    get scanDate() {
        return this.carsService.getPreviousDateByDay(this.graphDay);
    }

    get isNA() {
        return this.carsService.allCars(this.graphDay, true, undefined, this.calendarDataSource) === false;
    }

    get isSoldOut() {
        return this.carsService.currentCar(this.graphDay, this.calendarDataSource) === null;
    }

    get compsetDataType() {
        if (this.carsService) {
            return {
                isNA: this.isNA,
                isSoldOut: this.isSoldOut,
                isNoData: false,
            };
        }
        return {};
    }

    // get percent(): Percent | null {
    //     return this.carsService.competitorPercent(this.graphDay);
    // }

    // get cardColorClass() {
    //     if (!this.percent) {
    //         return { red: true };
    //     }
    //
    //     const assessment = this.carsService.getCardAssessment(this.percent);
    //
    //     return {
    //         green: assessment === ASSESSMENTS_TYPES.GOOD,
    //         yellow: assessment === ASSESSMENTS_TYPES.NORMAL,
    //         red: assessment === ASSESSMENTS_TYPES.BAD || this.isCompetitorsSoldOut(this.graphDay),
    //     };
    // }

    isCompetitorsSoldOut(day: Day) {
        return this.carsService.isCompetitorsSoldOut(day);
    }

    get currentDataSource() {
        const { isBrokerMode } = this.carsService;
        const { currentChainCode } = this.carsFiltersService;
        const { dataSource, currentDataSourceMode, currentBrandAsBroker } = this.carsService.storeState.settings;
        if (isBrokerMode) {
            const mode = Object.keys(this.modes).find(modeKey => this.modes[modeKey] === currentDataSourceMode);
            const [, brand = 'All'] = currentBrandAsBroker.split(',');
            return `${mode}/${currentChainCode} ${brand}`;
        }
        return dataSource;
    }

    reloadData() {
        this.carsPriceHistoryService.initCarRatesData(this.currentDay);
    }

    mounted() {
        this.reloadData();
    }

    get carClasses() {
        const { carClasses } = this.carsService.storeState.settings;
        if (carClasses) {
            const firstClass = carClasses[0] || '';
            return (carClasses.length > 1) ? `${firstClass}...` : `${firstClass}`;
        }
        return '';
    }

    get fuelTypes() {
        const { carFuelTypes } = this.carsService.storeState.settings;
        if (carFuelTypes) {
            const firstType = carFuelTypes[0] || '';
            return (carFuelTypes.length > 1) ? `${firstType}...` : `${firstType}`;
        }
        return '';
    }
}
